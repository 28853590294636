<template>
  <div>
    <div class="container-filters">
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mr-2">Doctors:</p>
        <v-select
          :disabled="localIsLoading || loading"
          :items="practitionersList"
          :value="pastTabFilter.practitionerId"
          dense
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeSelectedPractitionerData($event)"
        ></v-select>
      </div>
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mx-2">Date Range:</p>
        <RangeDatePicker
          :clearRangeDatePicker="false"
          :dense="true"
          :height="34"
          :hide-title="true"
          :hideDetails="true"
          :isYearFirst="false"
          :value="pastTabFilter.currentDate"
          className="date-picker__box-shadow v-input--hide-details--custom"
          @setDate="changeCurrentDate"
        />
      </div>
    </div>
    <div>
      <v-progress-linear v-if="loading || localIsLoading" color="primary" indeterminate />
      <div v-if="!archiveItems.length && !loading" class="ap-list_no-items">
        <img alt="No Items" src="@/assets/no-items-clock.svg" />
        <p class="heading-4">There is no appointments to display here</p>
        <p class="sub-heading-3">Book a visit with a doctor first.</p>
        <OutlinedButton
          color="primary"
          style="color: var(--primary); opacity: 1; border: 1px solid var(--primary)"
          text="Schedule a New Visit"
          @onClick="$emit('toSchedule')"
        />
      </div>
      <div class="ap-list">
        <div v-for="(archiveItems, key) in formatterItems" :key="key" class="ap-list">
          <h1 class="ap-list_wrapTitle">{{ formaWrapTitle(key) }}</h1>
          <AppointmentsListItem
            v-for="encounter in archiveItems"
            :key="encounter.id"
            :item="encounter"
            place="archive"
            role="patient"
            @onButtonClick="joinRoom"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import AppointmentsListItem from "@/components/AppointmentsListItem/index.vue";
import RangeDatePicker from "@/components/shared/RangeDatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { archiveListMixin } from "@/mixins/archiveList.js";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePatientsStore } from "@/pinia-store/patients";

export default {
  name: "Past",
  props: ["isLoading", "isSwitchTab"],
  components: {
    RangeDatePicker,
    AppointmentsListItem,
    OutlinedButton,
  },
  mixins: [archiveListMixin],
  computed: {
    ...mapState(useEncountersStore, ["pastTabFilter"]),
    ...mapState(usePatientsStore, ["practitioners"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["timeZone"]),
    formatterItems() {
      return groupBy(
        (this.archiveItems || []).map((i) => ({ ...i, _group: moment(i.start).format("YYYY-MM-DD") })),
        (i) => i._group,
      );
    },
    practitionersList() {
      const practitioners = this.practitioners.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
      practitioners.unshift({
        text: "All",
        value: null,
      });
      return practitioners;
    },
    localIsLoading: {
      get() {
        return this.isLoading;
      },
      set(newValue) {
        this.$emit("update:isLoading", newValue);
      },
    },
  },

  methods: {
    ...mapActions(useEncountersStore, ["getPatientPastEncounters"]),
    ...mapActions(useEncountersStore, ["setPastTabFilter"]),
    ...mapActions(useAppointmentsStore, ["getPastAppointments"]),
    formaWrapTitle(date) {
      return this.$moment(date).format("dddd, DD MMMM YYYY");
    },
    async changeSelectedPractitionerData(selected) {
      this.loading = true;
      try {
        let filters = { ...this.pastTabFilter, practitionerId: selected, past: true };
        this.setPastTabFilter(...filters);
        await Promise.all([
          this.getPatientPastEncounters(),
          this.getPastAppointments({
            ...this.pastTabFilter,
            status: "pending",
          }),
        ]);
        this.loading = false;
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.localIsLoading = false;
    },
    async changeCurrentDate(newDate) {
      const currentDate =
        newDate.length === 2 ? newDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf()) : newDate;
      this.setPastTabFilter({ ...this.pastTabFilter, currentDate, past: true });
      this.loading = true;
      try {
        await Promise.all([this.getPatientPastEncounters(), this.getPastAppointments(this.pastTabFilter)]);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-filters {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 335px));
  @include mobile {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 320px));
  }
  margin-bottom: 20px;
}
</style>
